import React, { useEffect } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { Link, useLocation } from "react-router-dom";
import { Price } from "../modules/result/components/Price";
import { NextSteps } from "../modules/result/components/nextSteps";
import { PriceBreakdown } from "../modules/result/components/PriceBreakdown";

export type EstimateResponseType = {
  es: {
    min: number;
    max: number;
    outcomes: {
      id: string;
      percentage: number;
    }[];
  };
  status: string;
};

export function ResultPage({
  result,
}: {
  result: EstimateResponseType | null;
}) {
  return (
    <div className="mx-auto mw-800px w-100 pt-15 px-3">
      <div className="pb-8 pb-lg-10">
        <h2 className="fw-bolder text-dark">Done!</h2>

        <div className="text-gray-400 fw-bold fs-6">
          The result is ready. You can find the project estimation and next
          steps below.
        </div>
      </div>

      <div className="row mb-0">
        <div className="col-12 pb-5">
          {result && <Price estimate={result.es} />}
        </div>

        <div className="col-12 fs-6 text-gray-600 mb-5">
          <div className="notice d-flex bg-light-info rounded border-info border border-dashed p-6">
            <KTSVG
              path="/media/icons/duotune/general/gen044.svg"
              className="svg-icon-2tx svg-icon-info me-4"
            />
            <div className="d-flex flex-stack flex-grow-1">
              <div className="fw-bold">
                <h4 className="text-gray-800 fw-bolder">Please Note!</h4>
                <p className="fs-6 text-gray-600">
                  Current estimation is calculated based on industry standards
                  and it been designed for budget planning purposes. To have
                  accurate estimation, please book a free discovery call.
                </p>

                <div className="text-center py-5">
                  <a href="https://calendly.com/salutech/30min" target="_blank">
                    <button type="button" className="btn btn-lg btn-info me-3">
                      Get Accurate Estimation
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 pb-5">
          {result && <PriceBreakdown outcomes={result.es.outcomes} />}
        </div>

        <div className="col-md-6 pb-5">
          <NextSteps />
        </div>

        {/* <div className="col-12 pb-5">
          <NextStepCTA />
        </div> */}

        {/* <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
          <KTSVG
            path="/media/icons/duotune/general/gen044.svg"
            className="svg-icon-2tx svg-icon-warning me-4"
          />
          <div className="d-flex flex-stack flex-grow-1">
            <div className="fw-bold">
              <h4 className="text-gray-800 fw-bolder">
                We need your attention!
              </h4>
              <div className="fs-6 text-gray-600">
                To start using great tools, please, please
                <a href="/dashboard" className="fw-bolder">
                  {" "}
                  Create Team Platform
                </a>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
