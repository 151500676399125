import React, { FC, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { ErrorMessage, useField } from "formik";
import { techStack } from "../../../../services/data";

const Step4: FC = () => {
  const [tech_list, setTech_list] = useState<string[]>([]);
  const [techStack_field, techStack_meta, techStack_helpers] =
    useField("techStack");

  const select_opt = (item) => {
    let a = tech_list;

    if (a.indexOf(item.value) == -1) {
      a.push(item.value);
    } else {
      a.splice(a.indexOf(item.value), 1);
    }
    
    setTech_list(a);
    techStack_helpers.setValue(a);
  };

  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-15">
        <h2 className="fw-bolder text-dark">Current Technology Stack</h2>

        <div className="text-gray-400 fw-bold fs-6">
          Please select all technologies that being used in product.
        </div>
      </div>

      {techStack.map((category, i) => (
        <div className="d-flex flex-column mb-5 fv-row" key={"cat_" + i}>
          <label className="d-flex align-items-center form-label mb-5">
            {category.name}
            <i
              className="fas fa-exclamation-circle ms-2 fs-7"
              data-bs-toggle="tooltip"
              title={category.description}
            ></i>
          </label>

          <div className="row mb-10">
            {category.items.map((tech, ii) => (
              <div
                className="col-3 col-md-3 pb-8 text-center"
                key={"tech_" + ii}
              >
                <label className="form-check-clip bg-hover-light-dark text-hover-dark">
                  <input
                    className="btn-check btn-outline"
                    type="checkbox"
                    checked={tech_list.indexOf(tech.value) != -1 ? true : false}
                    onChange={() => select_opt(tech)}
                    name="option"
                  />
                  <div className="form-check-wrapper">
                    <div className="symbol symbol-60px">
                      <img
                        src={tech.img}
                        alt={tech.name}
                        style={{
                          objectFit: "scale-down",
                          filter: "grayscale(30%)",
                        }}
                      />
                    </div>

                    <div className="form-check-indicator"></div>
                  </div>
                </label>
                <div
                  className="fs-7 text-gray-700"
                  style={{ whiteSpace: "break-spaces" }}
                >
                  {tech.name}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="text-danger mt-2">
        <ErrorMessage name="techStack" />
      </div>
    </div>
  );
};

export { Step4 };
