import React, { FC, useEffect, useRef, useState } from "react";
import { Step1 } from "./steps/Step1";
import { Step2 } from "./steps/Step2";
import { Step3 } from "./steps/Step3";
import { Step4 } from "./steps/Step4";
import { Step5 } from "./steps/Step5";
import { Step6 } from "./steps/Step6";
import { KTSVG } from "../../../../_metronic/helpers";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { Formik, Form, FormikValues } from "formik";
import {
  createAccountSchemas,
  ICreateAccount,
  inits,
} from "./CreateAccountWizardHelper";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Horizontal: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0]);
  const [initValues] = useState<ICreateAccount>(inits);
  const [isSubmitButton, setSubmitButton] = useState(false);
  const navigate = useNavigate();

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    setSubmitButton(
      stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1
    );

    stepper.current.goPrev();

    setCurrentSchema(
      createAccountSchemas[stepper.current.currentStepIndex - 1]
    );
  };

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return;
    }

    setSubmitButton(
      stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1
    );

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex]);

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext();
    } else {
      // on submit
      submitData(values);
    }
  };

  const submitData = async (data: ICreateAccount) => {
    console.log("Submit", data);
    try {
      const response = await axios.post("https://m1pne2nf9g.execute-api.eu-west-2.amazonaws.com/prod/devops-api-resource", data);
      navigate('/result', { state: { data: response.data } });
      localStorage.setItem('lastEstimate', JSON.stringify(response.data));
      console.log(response.status);
    } catch (error) {
      console.error(error);
    }
    // window.location.href = "/result";
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
  }, [stepperRef]);

  return (
    <div className="card">
      <div className="card-body">
        <div
          ref={stepperRef}
          className="stepper stepper-links d-flex flex-column pt-2"
          id="kt_create_account_stepper"
        >
          <div className="stepper-nav mb-5">
            <div className="stepper-item current" data-kt-stepper-element="nav">
              <h3 className="stepper-title">Stage</h3>
            </div>

            <div className="stepper-item" data-kt-stepper-element="nav">
              <h3 className="stepper-title">Data Scope</h3>
            </div>

            <div className="stepper-item" data-kt-stepper-element="nav">
              <h3 className="stepper-title">Goal</h3>
            </div>

            <div className="stepper-item" data-kt-stepper-element="nav">
              <h3 className="stepper-title">Tech Stack</h3>
            </div>

            <div className="stepper-item" data-kt-stepper-element="nav">
              <h3 className="stepper-title">Infrastructure</h3>
            </div>

            <div className="stepper-item" data-kt-stepper-element="nav">
              <h3 className="stepper-title">Team</h3>
            </div>
          </div>

          <Formik
            validationSchema={currentSchema}
            initialValues={initValues}
            onSubmit={submitStep}
          >
            {() => (
              <Form
                className="mx-auto mw-600px w-100 pt-5 pb-5"
                id="kt_create_account_form"
              >
                <div className="">
                  <div className="current" data-kt-stepper-element="content">
                    <Step1 />
                  </div>

                  <div data-kt-stepper-element="content">
                    <Step2 />
                  </div>

                  <div data-kt-stepper-element="content">
                    <Step3 />
                  </div>

                  <div data-kt-stepper-element="content">
                    <Step4 />
                  </div>

                  <div data-kt-stepper-element="content">
                    <Step5 />
                  </div>
                  <div data-kt-stepper-element="content">
                    <Step6 />
                  </div>
                </div>

                <div className="d-flex flex-stack pt-15">
                  <div className="mr-2">
                    <button
                      onClick={prevStep}
                      type="button"
                      className="btn btn-lg btn-light-primary me-3"
                      data-kt-stepper-action="previous"
                    >
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr063.svg"
                        className="svg-icon-4 me-1"
                      />
                      Back
                    </button>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary me-3"
                    >
                      <span className="indicator-label">
                        {!isSubmitButton && "Continue"}
                        {isSubmitButton && "Submit"}
                        <KTSVG
                          path="/media/icons/duotune/arrows/arr064.svg"
                          className="svg-icon-3 ms-2 me-0"
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export { Horizontal };
