import * as Yup from 'yup'

export interface ICreateAccount {
  productStage: string
  activeUsers: string
  currentSLA: string
  currentSecurity: string
  outcome: string[]
  techStack: string[]
  repoCount: number
  vpsCount: number
  waf: string
  currentBudget: number
  currentTeam: string
  contactName: string
  contactEmail: string
  contactPhone: string
}

const createAccountSchemas = [
  Yup.object({
    productStage: Yup.string().required().label('Product Stage'),
  }),
  Yup.object({
    activeUsers: Yup.string().required().label('Active Users'),
    currentSLA: Yup.string().required().label('Current Service Availability'),
    currentSecurity: Yup.string().required().label('Current Data Security Level'),
  }),
  Yup.object({
    outcome: Yup.array().required().defined().min(1).label('Outcome')
  }),
  Yup.object({
    techStack: Yup.array().required().defined().min(3).label('Technology Stack')
  }),
  Yup.object({
    repoCount: Yup.number().min(1).required().label('Number of Repo'),
    vpsCount: Yup.number().min(1).required().label('Number of Server'),
    waf: Yup.string().required().label('WAF'),
    currentBudget: Yup.number().min(1).required().label('Current Budget'),
  }),
  Yup.object({
    currentTeam: Yup.string().required().label('Current Team'),
    contactName: Yup.string().required().label('Name'),
    contactEmail: Yup.string().email().required().label('Email'),
    contactPhone: Yup.string().required().label('Phone'),
  }),
]

const inits: ICreateAccount = {
  productStage: '',
  activeUsers: '',
  currentSLA: '',
  currentSecurity: '',
  outcome: [],
  techStack: [],
  repoCount: 1,
  vpsCount: 1,
  waf: '',
  currentBudget: 1500,
  currentTeam: '',
  contactName: '',
  contactEmail: '',
  contactPhone: '',
}

export {createAccountSchemas, inits}
