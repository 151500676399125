import {FC, Suspense} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import { DevOpsCalculatorPage } from '../pages/DevOpsCalculator'
import { EstimateResponseType, ResultPage } from '../pages/ResultPage'

const WrapCalculator = () => {
  const lastEstimate = localStorage.getItem('lastEstimate');
  if ( lastEstimate ) {
    return <Navigate to="/result" replace={true} state={JSON.parse(lastEstimate)} />
  } 
  return <DevOpsCalculatorPage />
}

const WrapResult = ({children}: WithChildren) => {
  const location = useLocation();
  const lastEstimate = localStorage.getItem('lastEstimate');

  const estimateToShow = (location.state && (location.state as any).data) || (lastEstimate ? JSON.parse(lastEstimate) : null);

  if ( !estimateToShow ) {
    return <Navigate to="/dev-ops" replace={true} />
  } 
  return <ResultPage result={estimateToShow as EstimateResponseType } />
}

const PrivateRoutes = () => {
  return (
    <Routes>
        <Route path="/dev-ops" element={<WrapCalculator />} />
        <Route path="/result" element={<WrapResult />} />
      <Route element={<MasterLayout />}>
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/dev-ops' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
