import React, { FC } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { Field, ErrorMessage } from "formik";
import { teamOptions } from "../../../../services/data";



const Step6: FC = () => {
  return (
    <div className="w-100">
      {/* <div className="pb-10 pb-lg-15">
        <h2 className="fw-bolder text-dark">Team</h2>

        <div className="text-gray-400 fw-bold fs-6">
          If you need more info, please check out
          <a href="/dashboard" className="link-primary fw-bolder">
            {" "}
            Help Page
          </a>
          .
        </div>
      </div> */}

      <div className="mb-10 fv-row">
        <label className="d-flex align-items-center form-label mb-3">
          Current Development Team
        </label>
        <div className="row mb-2" data-kt-buttons="true">
          {teamOptions.map((opt,i) => (
            <div className="col" key={'team_option_'+i}>
              <Field
                type="radio"
                className="btn-check"
                name="currentTeam"
                value={opt.value}
                id={opt.stageFieldId}
              />
              <label
                className="btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-3"
                htmlFor={opt.stageFieldId}
              >
                <span className="fw-semibold fs-4">{opt.title}</span>
              </label>
            </div>
          ))}
        </div>
        <div className="text-danger mt-2">
          <ErrorMessage name="currentTeam" />
        </div>
      </div>

      <div className="fv-row mb-10">
      <label className="d-flex align-items-center form-label mb-3">
          Your Contact Information
        </label>
        <label className="d-flex align-items-center form-label">
          <span className="required">Name</span>
        </label>

        <Field
          name="contactName"
          className="form-control form-control-lg form-control-solid"
        />
        <div className="text-danger mt-2">
          <ErrorMessage name="contactName" />
        </div>
      </div>

      <div className="fv-row mb-10">
        <label className="d-flex align-items-center form-label">
          <span className="required">Email</span>
        </label>

        <Field
          name="contactEmail"
          className="form-control form-control-lg form-control-solid"
        />
        <div className="text-danger mt-2">
          <ErrorMessage name="contactEmail" />
        </div>
      </div>

      <div className="fv-row mb-10">
        <label className="d-flex align-items-center form-label">
          <span className="required">Phone</span>
        </label>

        <Field
          name="contactPhone"
          className="form-control form-control-lg form-control-solid"
        />
        <div className="text-danger mt-2">
          <ErrorMessage name="contactPhone" />
        </div>
      </div>
    </div>
  );
};

export { Step6 };
