import React, { FC } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { Field, ErrorMessage } from "formik";
import {
  monthActiveUsersOptions,
  securityOptions,
  slaOptions,
} from "../../../../services/data";

const Step2: FC = () => {
  return (
    <div className="w-100">
      {/* <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Data</h2>
        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div> */}

      <div className="mb-15 fv-row">
        <label className="d-flex align-items-center form-label mb-3">
          Monthly Active Users
        </label>

        <div className="row mb-2" data-kt-buttons="true">
          {monthActiveUsersOptions.map((opt, i) => (
            <div className="col" key={"active_user_option_" + i}>
              <Field
                type="radio"
                className="btn-check"
                name="activeUsers"
                value={opt.value}
                id={opt.stageFieldId}
              />
              <label
                className="btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-3"
                htmlFor={opt.stageFieldId}
              >
                <span className="fw-semibold fs-4">{opt.title}</span>
              </label>
            </div>
          ))}
        </div>

        <div className="form-text">
          Customers will see this shortened version of your statement descriptor
        </div>
        <div className="text-danger mt-2">
          <ErrorMessage name="activeUsers" />
        </div>
      </div>

      <div className="mb-0 pb-15 fv-row">
        <label className="d-flex align-items-center form-label mb-5">
          Current Service availability (SLA)
          <i
            className="fas fa-exclamation-circle ms-2 fs-7"
            data-bs-toggle="tooltip"
            title="Monthly billing will be based on your account plan"
          ></i>
        </label>

        <div className="mb-">
          {slaOptions.map((slaOpt,i) => (
            <label className="d-flex flex-stack mb-5 cursor-pointer bg-hover-light-primary text-hover-primary" key={"sla_option_" + i}>
              <span className="d-flex align-items-center me-2">
                <span className="symbol symbol-50px me-6">
                  <span className="symbol-label">
                    <KTSVG
                      path={slaOpt.img}
                      className="svg-icon-1 svg-icon-gray-600"
                    />
                  </span>
                </span>

                <span className="d-flex flex-column">
                  <span className="fw-bold fs-5">{slaOpt.title}</span>
                  <span className="fs-6 fw-semibold opacity-50">
                    {slaOpt.description}
                  </span>
                </span>
              </span>

              <span className="form-check form-check-custom form-check-solid ">
                <Field
                  className="form-check-input"
                  type="radio"
                  name="currentSLA"
                  value={slaOpt.value}
                />
              </span>
            </label>
          ))}
        </div>
        <div className="text-danger mt-2">
          <ErrorMessage name="currentSLA" />
        </div>
      </div>

      <div className="mb-0 fv-row">
        <label className="d-flex align-items-center form-label mb-5">
          Current Data Security Level
          <i
            className="fas fa-exclamation-circle ms-2 fs-7"
            data-bs-toggle="tooltip"
            title="Monthly billing will be based on your account plan"
          ></i>
        </label>

        <div className="mb-0">
          {securityOptions.map((secOpt,i) => (
            <label className="d-flex flex-stack mb-5 cursor-pointer bg-hover-light-primary text-hover-primary" key={"sec_option_" + i}>
              <span className="d-flex align-items-center me-2">
                <span className="symbol symbol-50px me-6">
                  <span className="symbol-label">
                    <KTSVG
                      path={secOpt.img}
                      className="svg-icon-1 svg-icon-gray-600"
                    />
                  </span>
                </span>

                <span className="d-flex flex-column">
                  <span className="fw-bold fs-5">{secOpt.title}</span>
                  <span className="fs-6 fw-semibold  opacity-50">
                    {secOpt.description}
                  </span>
                </span>
              </span>

              <span className="form-check form-check-custom form-check-solid">
                <Field
                  className="form-check-input"
                  type="radio"
                  name="currentSecurity"
                  value={secOpt.value}
                />
              </span>
            </label>
          ))}
        </div>
        <div className="text-danger mt-2">
          <ErrorMessage name="currentSecurity" />
        </div>
      </div>
    </div>
  );
};

export { Step2 };
