/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { Field, ErrorMessage } from "formik";
import { productStages } from "../../../../services/data";

const Step1: FC = () => {
  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-15">
        <h2 className="fw-bolder d-flex align-items-center text-dark ">
          Choose Product Stage
        </h2>
      </div>

      <div className="fv-row">
        <div className="row">
          {productStages.map((stage,i) => (
            <div className="col-lg-6" key={'product_stage_'+i}>
              <Field
                type="radio"
                className="btn-check"
                name="productStage"
                value={stage.stageValue}
                id={stage.stageFieldId}
              />
              <label
                className="btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary p-7 d-flex align-items-center mb-10"
                htmlFor={stage.stageFieldId}
              >
                <KTSVG path={stage.stageImg} className="svg-icon-3x me-5" />

                <span className="d-block fw-bold text-start">
                  <span className="text-dark fw-bold d-block fs-3 mb-2">
                    {stage.stageName}
                  </span>
                  <span className="text-muted fw-normal fs-6">
                    {stage.stageDescription}
                  </span>
                </span>
              </label>
            </div>
          ))}

          <div className="text-danger mt-2">
            <ErrorMessage name="productStage" />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Step1 };
