import { FC } from "react";
import { Link } from "react-router-dom";
import { KTSVG } from "../../../../_metronic/helpers";

const NextSteps: FC = () => {
  const steps = [
    {
      title: "Discovery",
      subtitle: "subtitle",
      tag: "Done",
      img: "/media/icons/duotune/general/gen004.svg",
      subSteps: [
        {
          title: "Online estimation",
          subtitle: "sub step 1 subtitile",
        },
        {
          title: "Discovery call",
          subtitle: "sub step 1 subtitile",
        },
      ],
    },
    {
      title: "Solution Design",
      subtitle: "subtitle",
      tag: "Done",
      img: "/media/icons/duotune/abstract/abs026.svg",
      subSteps: [
        {
          title: "Sign NDA",
          subtitle: "sub step 1 subtitile",
        },
        {
          title: "In-depth Technical Sessions",
          subtitle: "sub step 1 subtitile",
        },
        {
          title: "Provide Action Plan & Network Blueprint",
          subtitle: "sub step 1 subtitile",
        },
      ],
    },
    {
      title: "Project Delivery",
      subtitle: "subtitle",
      tag: "Done",
      img: "/media/icons/duotune/general/gen002.svg",
      subSteps: [
        {
          title: "Setup Agile Workload Between Teams",
          subtitle: "sub step 1 subtitile",
        },
        {
          title: "Onboarding",
          subtitle: "sub step 1 subtitile",
        },
        {
          title: "Weekly Update",
          subtitle: "sub step 1 subtitile",
        },
        {
          title: "Handover",
          subtitle: "sub step 1 subtitile",
        },
      ],
    },
    {
      title: "Support",
      subtitle: "subtitle",
      tag: "Done",
      img: "/media/icons/duotune/general/gen026.svg",
      subSteps: [
        {
          title: "Free 15-Day Support",
          subtitle: "sub step 1 subtitile",
        },
        {
          title: "6-Month Extended Support",
          subtitle: "sub step 1 subtitile",
        },
      ],
    },
  ];

  return (
    <>
      <div className="card card-flush h-lg-100">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark">
              What is next step?
            </span>
            <span className="text-muted mt-1 fw-semibold fs-7">
              Here are the possible next steps to reach your goal.
            </span>
          </h3>
        </div>
        <div className="card-body card-scroll h-400px">
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="kt_list_widget_10_tab_3"
            >
              {steps.map((s, i) => (
                <div key={"step_" + i}>
                  <div className="m-0">
                    <div className="d-flex align-items-sm-center mb-5">
                      <div className="symbol symbol-45px me-4">
                        <span className="symbol-label bg-light-primary">
                          <KTSVG
                            path={s.img}
                            className="svg-icon svg-icon-2x text-primary"
                          />
                        </span>
                      </div>
                      <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                        <div className="flex-grow-1 me-2">
                          {/* <span className="text-gray-400 fs-6 fw-semibold">
                            {s.subtitle}
                          </span> */}
                          <span className="text-gray-800 fw-bold d-block fs-4">
                            {s.title}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="timeline">
                      {s.subSteps.map((ss, ii) => (
                        <div
                          className={`timeline-item align-items-center mb-7`}
                          key={"Substep_" + ii}
                        >
                          <div
                            className={`timeline-line w-40px ${
                              ii != s.subSteps.length - 1 ? "mt-6 mb-n12" : ""
                            } `}
                          ></div>
                          <div
                            className="timeline-icon"
                            style={{ marginLeft: "11px" }}
                          >
                            <span className="svg-icon svg-icon-2 svg-icon-danger">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  opacity="0.3"
                                  d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM6.39999 9.89999C6.99999 8.19999 8.40001 6.9 10.1 6.4C10.6 6.2 10.9 5.7 10.7 5.1C10.5 4.6 9.99999 4.3 9.39999 4.5C7.09999 5.3 5.29999 7 4.39999 9.2C4.19999 9.7 4.5 10.3 5 10.5C5.1 10.5 5.19999 10.6 5.39999 10.6C5.89999 10.5 6.19999 10.2 6.39999 9.89999ZM14.8 19.5C17 18.7 18.8 16.9 19.6 14.7C19.8 14.2 19.5 13.6 19 13.4C18.5 13.2 17.9 13.5 17.7 14C17.1 15.7 15.8 17 14.1 17.6C13.6 17.8 13.3 18.4 13.5 18.9C13.6 19.3 14 19.6 14.4 19.6C14.5 19.6 14.6 19.6 14.8 19.5Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M16 12C16 14.2 14.2 16 12 16C9.8 16 8 14.2 8 12C8 9.8 9.8 8 12 8C14.2 8 16 9.8 16 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </div>
                          <div className="timeline-content m-0">
                            <span className="fs-6 fw-bold text-gray-400">
                              {ss.title}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="separator separator-dashed my-6"></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { NextSteps };
