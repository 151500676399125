const productStages = [
  {
    stageName: "Pre-Launch",
    stageDescription: "Product is under developmnet.",
    stageValue: "prelaunch",
    stageFieldId: "kt_product_stage_form_prelaunch",
    stageImg: "/media/icons/duotune/coding/cod003.svg",
  },
  {
    stageName: "MVP",
    stageDescription: "First version of product is online with live users.",
    stageValue: "mvp",
    stageFieldId: "kt_product_stage_form_mvp",
    stageImg: "/media/icons/duotune/coding/cod006.svg",
  },
  {
    stageName: "Growing Product",
    stageDescription: "Product is gaining more users everyday.",
    stageValue: "growing",
    stageFieldId: "kt_product_stage_form_growing",
    stageImg: "/media/icons/duotune/general/gen002.svg",
  },
  {
    stageName: "Unicorn",
    stageDescription: "Product has +1M active users worldwide.",
    stageValue: "unicorn",
    stageFieldId: "kt_product_stage_form_unicorn",
    stageImg: "/media/icons/duotune/abstract/abs024.svg",
  },
  {
    stageName: "Enterprise App",
    stageDescription:
      "Product has large customer base or being used by large orginazations.",
    stageValue: "enterprise",
    stageFieldId: "kt_product_stage_form_enterprise",
    stageImg: "/media/icons/duotune/general/gen026.svg",
  },
];
const monthActiveUsersOptions = [
  {
    title: "0 - 1k",
    value: "1k",
    stageFieldId: "kt_monthly_active_form_1k",
  },
  {
    title: "1k - 10k",
    value: "10k",
    stageFieldId: "kt_monthly_active_form_10k",
  },
  {
    title: "10k - 100k",
    value: "100k",
    stageFieldId: "kt_monthly_active_form_100k",
  },
  {
    title: "100k - 1m",
    value: "1m",
    stageFieldId: "kt_monthly_active_form_1m",
  },
  {
    title: "+1m",
    value: "+1m",
    stageFieldId: "kt_monthly_active_form_p1m",
  },
];
const slaOptions = [
  {
    title: "Mission Critical",
    description: "%99.999 uptime. 0.86s Daily or 26s Monthly downtime",
    value: "critical",
    img: "/media/icons/duotune/general/gen043.svg",
  },
  {
    title: "Very High",
    description: "%99.99 uptime. 8.6s Daily or 60.48s Monthly downtime",
    value: "very-high",
    img: "/media/icons/duotune/general/gen043.svg",
  },
  {
    title: "High",
    description: "%99.9 uptime. 1m 26s Daily or 43m 28s Monthly downtime",
    value: "high",
    img: "/media/icons/duotune/general/gen043.svg",
  },
  {
    title: "Moderate",
    description: "%99 uptime. 14m 24s Daily or 7h 14m Monthly downtime",
    value: "moderate",
    img: "/media/icons/duotune/general/gen043.svg",
  },
  {
    title: "Normal",
    description: "%90 uptime. 2h 24m Daily or 36h 26m Monthly",
    value: "normal",
    img: "/media/icons/duotune/general/gen043.svg",
  },
];
const securityOptions = [
  {
    title: "Mission Critical",
    description: "%99.999 uptime. 0.86s Daily or 26s Monthly downtime",
    value: "critical",
    img: "/media/icons/duotune/general/gen051.svg",
  },
  {
    title: "Very High",
    description: "%99.99 uptime. 8.6s Daily or 60.48s Monthly downtime",
    value: "very-high",
    img: "/media/icons/duotune/general/gen051.svg",
  },
  {
    title: "High",
    description: "%99.9 uptime. 1m 26s Daily or 43m 28s Monthly downtime",
    value: "high",
    img: "/media/icons/duotune/general/gen051.svg",
  },
  {
    title: "Moderate",
    description: "%99 uptime. 14m 24s Daily or 7h 14m Monthly downtime",
    value: "moderate",
    img: "/media/icons/duotune/general/gen051.svg",
  },
  {
    title: "Normal",
    description: "%90 uptime. 2h 24m Daily or 36h 26m Monthly",
    value: "normal",
    img: "/media/icons/duotune/general/gen051.svg",
  },
];
const outcomeOptions = [
  {
    title: "Reduce Costs",
    value:"decrease-costs",
    description: "Reduce cost description",
    img: "/media/icons/duotune/finance/fin010.svg",
  },
  {
    title: "Scale",
    value:"scale",
    description: "Scale description",
    img: "/media/icons/duotune/graphs/gra004.svg",
  },
  {
    title: "Increase Availability",
    value:"availability",
    description: "Increase Availability description",
    img: "/media/icons/duotune/electronics/elc007.svg",
  },
  {
    title: "Increase Security",
    value:"security",
    description: "Increase Security description",
    img: "/media/icons/duotune/general/gen051.svg",
  },
  {
    title: "Increase Product Performance",
    value:"performance",
    description: "Increase Product Performance description",
    img: "/media/icons/duotune/coding/cod006.svg",
  },
  {
    title: "Better Resource Monitoring",
    value:"monitoring",
    description: "Better Resource Monitoring description",
    img: "/media/icons/duotune/general/gen030.svg",
  },
  {
    title: "Improve Code Quality",
    value:"code-quality",
    description: "Improve Code Quality description",
    img: "/media/icons/duotune/coding/cod003.svg",
  },
  {
    title: "Improve Test Automation",
    value:"test-automation",
    description: "Improve Test Automation description",
    img: "/media/icons/duotune/general/gen004.svg",
  },
];
const techStack = [
  {
    name: "Front-End: CMS-based",
    description: "Hosted CMS-based websites",
    items: [
      {
        name: "WordPress",
        value: "wordpress",
        img: "/media/dev_logos/wordpress.png",
      },
      {
        name: "Drupal",
        value: "drupal",
        img: "/media/dev_logos/drupal.png",
      },
      {
        name: "Joomla",
        value: "joomla",
        img: "/media/dev_logos/joomla.png",
      },
      {
        name: "Shopify",
        value: "shopify",
        img: "/media/dev_logos/shopify.png",
      },
      {
        name: "Magento",
        value: "magento",
        img: "/media/dev_logos/magento.png",
      },
    ],
  },
  {
    name: "Front-End: Web & Webapp",
    description: "Website or Webapp that are hosted by you",
    items: [
      {
        name: "React",
        value: "react",
        img: "/media/dev_logos/react.png",
      },
      {
        name: "Vue",
        value: "vue",
        img: "/media/dev_logos/vue.png",
      },
      {
        name: "Angular",
        value: "angular",
        img: "/media/dev_logos/angular.png",
      },
      {
        name: "JQuery",
        value: "jquery",
        img: "/media/dev_logos/js.png",
      },
      {
        name: ".Net",
        value: "front_net",
        img: "/media/dev_logos/net.png",
      },
    ],
  },
  {
    name: "Front-End: Mobile App",
    description: " Front desc",
    items: [
      {
        name: "Native iOS",
        value: "ios",
        img: "/media/dev_logos/apple.png",
      },
      {
        name: "Native Android",
        value: "android",
        img: "/media/dev_logos/android.png",
      },
      {
        name: "Cross-Platform",
        value: "cross_platform",
        img: "/media/dev_logos/cross_platform.png",
      },
    ],
  },
  {
    name: "Back-End",
    description: " Front desc",
    items: [
      {
        name: "LAMP \n(Linux, Apache, PHP)",
        value: "lamp",
        img: "/media/dev_logos/xammp.png",
      },
      {
        name: "Node.js \n(JS, TS)",
        value: "node",
        img: "/media/dev_logos/nodejs.png",
      },
      {
        name: "Django",
        value: "django",
        img: "/media/dev_logos/django.png",
      },
      {
        name: "Django REST",
        value: "django_rest",
        img: "/media/dev_logos/django.png",
      },
      {
        name: "Ruby on Rails",
        value: "ruby",
        img: "/media/dev_logos/ruby.png",
      },
      {
        name: "Phoenix \n(Elixir, Phoenix)",
        value: "phoenix",
        img: "/media/dev_logos/elixir.png",
      },
      {
        name: "Sinatra \n(Ruby, Sinatra)",
        value: "sinatra",
        img: "/media/dev_logos/sinatra.png",
      },
      {
        name: "Spring",
        value: "spring",
        img: "/media/dev_logos/spring.png",
      },
      {
        name: "Spring Boot",
        value: "spring_boot",
        img: "/media/dev_logos/spring_boot.png",
      },
      {
        name: "ASP.NET",
        value: "back_net",
        img: "/media/dev_logos/net.png",
      },
      {
        name: "ASP.NET Core",
        value: "back_net_core",
        img: "/media/dev_logos/asp_net_core.png",
      },
      {
        name: "Laravel",
        value: "laravel",
        img: "/media/dev_logos/laravel.png",
      },
      {
        name: "Flask",
        value: "flask",
        img: "/media/dev_logos/flask.png",
      },
      {
        name: "Flask-RESTful",
        value: "flast_rest",
        img: "/media/dev_logos/flask.png",
      },
      {
        name: "Go \n(Gin, Echo, Beego)",
        value: "go",
        img: "/media/dev_logos/go.png",
      },
      {
        name: "Serverless \n(AWS lambda, etc.)",
        value: "serverless",
        img: "/media/dev_logos/serverless.png",
      },
      {
        name: "Other",
        value: "back_other",
        img: "/media/dev_logos/other.png",
      },
    ],
  },
  {
    name: "Database",
    description: " Front desc",
    items: [
      {
        name: "MySQL",
        value: "mysql",
        img: "/media/dev_logos/mysql.png",
      },
      {
        name: "Microsoft SQL Server",
        value: "mssql",
        img: "/media/dev_logos/mssql.png",
      },
      {
        name: "Oracle",
        value: "oracle",
        img: "/media/dev_logos/oracle.png",
      },
      {
        name: "Oracle NoSQL",
        value: "oracle_nosql",
        img: "/media/dev_logos/oracle.png",
      },
      {
        name: "PostgreSQL",
        value: "postgresql",
        img: "/media/dev_logos/postgresql.png",
      },
      {
        name: "MongoDB",
        value: "mongodb",
        img: "/media/dev_logos/mongodb.png",
      },
      {
        name: "MariaDB",
        value: "mariadb",
        img: "/media/dev_logos/mariadb.png",
      },
      {
        name: "Cassandra",
        value: "cassandra",
        img: "/media/dev_logos/cassandra.png",
      },
      {
        name: "ElasticSearch",
        value: "elastic",
        img: "/media/dev_logos/elasticsearch.png",
      },
      {
        name: "Neo4j",
        value: "neo4j",
        img: "/media/dev_logos/neo4j.png",
      },
      {
        name: "RethinkDB",
        value: "rethinkdb",
        img: "/media/dev_logos/rethinkdb.png",
      },
      {
        name: "Couchbase",
        value: "couchbase",
        img: "/media/dev_logos/couchbase.png",
      },
      {
        name: "Other",
        value: "db_other",
        img: "/media/dev_logos/other_db.png",
      },
    ],
  },
  {
    name: "Caching",
    description: " Front desc",
    items: [
      {
        name: "Redis",
        value: "redis",
        img: "/media/dev_logos/redis.svg",
      },
      {
        name: "Memcached",
        value: "memcached",
        img: "/media/dev_logos/memcached.png",
      },
      {
        name: "MemcacheDB",
        value: "memcachedb",
        img: "/media/dev_logos/memcached.png",
      },
      {
        name: "Microsoft Azure Cache for Redis",
        value: "redis_azure",
        img: "/media/dev_logos/azure_redis.png",
      },
      {
        name: "Amazon ElastiCache",
        value: "aws_elasti_cache",
        img: "/media/dev_logos/aws_redis.jpeg",
      },
      {
        name: "Google Cloud Memorystore",
        value: "google_memorystore",
        img: "/media/dev_logos/gcp_redis.png",
      },
      {
        name: "Other",
        value: "cache_other",
        img: "/media/dev_logos/other_db.png",
      },
    ],
  },
  {
    name: "Infrastructure",
    description: " Front desc",
    items: [
      {
        name: "AWS",
        value: "aws",
        img: "/media/dev_logos/aws.jpg",
      },
      {
        name: "Google Cloud",
        value: "gcp",
        img: "/media/dev_logos/gcp.png",
      },
      {
        name: "Azure",
        value: "azure",
        img: "/media/dev_logos/azure.png",
      },
      {
        name: "In-house Server Room",
        value: "server_room",
        img: "/media/dev_logos/server_room.png",
      },
      {
        name: "Collocated Server",
        value: "colocated",
        img: "/media/dev_logos/servers.png",
      },
      {
        name: "Dedicated Server",
        value: "dedicated",
        img: "/media/dev_logos/servers.png",
      },
      {
        name: "Private Cloud",
        value: "private_cloud",
        img: "/media/dev_logos/private_cloud.png",
      },
      {
        name: "Virtual Servers",
        value: "vps",
        img: "/media/dev_logos/vps.png",
      },
    ],
  },
  {
    name: "Code Management: Repo",
    description: " Front desc",
    items: [
      {
        name: "Github",
        value: "github",
        img: "/media/dev_logos/github.png",
      },
      {
        name: "GitLab",
        value: "gitlab",
        img: "/media/dev_logos/gitlab.png",
      },
      {
        name: "Bitbucket",
        value: "bitbucket",
        img: "/media/dev_logos/bitbucket.png",
      },
    ],
  },
  {
    name: "Code Management: Tools",
    description: " Front desc",
    items: [
      {
        name: "Jenkins",
        value: "jenkins",
        img: "/media/dev_logos/jenkins.png",
      },
      {
        name: "GitLab CI/CD",
        value: "gitlab_ci",
        img: "/media/dev_logos/gitlab.png",
      },
      {
        name: "Azure DevOps",
        value: "azure_devops",
        img: "/media/dev_logos/azure_devops.png",
      },
      {
        name: "AWS CodePipeline",
        value: "aws_pipeline",
        img: "/media/dev_logos/aws_codepipeline.jpeg",
      },
      {
        name: "Circle CI",
        value: "circle_ci",
        img: "/media/dev_logos/circle_ci.png",
      },
      {
        name: "Travis CI",
        value: "travis_ci",
        img: "/media/dev_logos/travis.png",
      },
      {
        name: "Bamboo",
        value: "bamboo",
        img: "/media/dev_logos/bamboo.png",
      },
    ],
  },
  {
    name: "Container System",
    description: " Front desc",
    items: [
      {
        name: "Docker Swarm",
        value: "docker",
        img: "/media/dev_logos/docker.png",
      },
      {
        name: "Kubernetes",
        value: "kubernetes",
        img: "/media/dev_logos/kubernetes.png",
      },
      {
        name: "Amazon Elastic Container Service (ECS)",
        value: "aws_ecs",
        img: "/media/dev_logos/aws_ecs.png",
      },
      {
        name: "Azure Kubernetes Service (AKS)",
        value: "azure_aks",
        img: "/media/dev_logos/azure_aks.svg",
      },
      {
        name: "Google Kubernetes Engine (GKE)",
        value: "gcp_gke",
        img: "/media/dev_logos/gcp_gke.png",
      },
      {
        name: "Amazon Elastic Kubernetes Service (EKS)",
        value: "aws_eks",
        img: "/media/dev_logos/aws_eks.jpeg",
      },
    ],
  },
  {
    name: "Other Technologies",
    description: " Front desc",
    items: [
      {
        name: "Big Data",
        value: "big_data",
        img: "/media/dev_logos/bigdata.png",
      },
      {
        name: "Cloud Storage",
        value: "cloud_storage",
        img: "/media/dev_logos/cloud_storage.png",
      },
      {
        name: "Artificial intelligence (A.I)",
        value: "ai",
        img: "/media/dev_logos/ai.png",
      },
      {
        name: "Blockchain Connection",
        value: "blockchain",
        img: "/media/dev_logos/blockchain.png",
      },
    ],
  },
];

const teamOptions = [
  {
    title: "Full-Time Team",
    value: "full",
    stageFieldId: "kt_team_full",
  },
  {
    title: "Part-Time Team",
    value: "part",
    stageFieldId: "kt_team_part",
  },
  {
    title: "Outsourced",
    value: "outsourced",
    stageFieldId: "kt_team_outsourced",
  },
];
export {
  productStages,
  monthActiveUsersOptions,
  slaOptions,
  securityOptions,
  outcomeOptions,
  techStack,
  teamOptions
};
