import React, { FC, useState } from "react";
import { Field, ErrorMessage, useField } from "formik";
import { KTSVG, toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { outcomeOptions } from "../../../../services/data";

const Step3: FC = () => {
  const [outcome_list, setOutcome_list] = useState<string[]>([]);
  const [outcome_field, outcome_meta, outcome_helpers] = useField("outcome");

  const select_opt = (item) => {
    let a = outcome_list;
    
    if (a.indexOf(item.value) == -1) {
      a.push(item.value);
    } else {
      a.splice(a.indexOf(item.value), 1);
    }
    setOutcome_list(a);
    outcome_helpers.setValue(a);
  };

  return (
    <div className="w-100">
      <div className="mb-15 fv-row">
        <label className="d-flex align-items-center form-label mb-3">
          What would you like to archive? {outcome_list}
        </label>
        <div className="form-text">
          Customers will see this shortened version of your statement descriptor
        </div>
        {/* <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
          <KTSVG
            path="/media/icons/duotune/art/art006.svg"
            className="svg-icon-2tx svg-icon-primary me-4"
          />
          <div className="d-flex flex-stack flex-grow-1">
            <div className="fw-bold">
              <div className="fs-6 text-gray-600">
                Two-factor authentication adds an extra layer of security to
                your account. To log in, in you'll need to provide a 4 digit
                amazing code.
                <a href="#" className="fw-bolder">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div> */}

        <div className="pt-10">
          {outcomeOptions.map((outcome, i) => (
            <div key={"outcome_option_" + i}>
              <div className="d-flex flex-stack">
                <div className="d-flex">
                  <KTSVG
                    path={outcome.img}
                    className="svg-icon svg-icon-3x svg-icon-info me-6"
                  />

                  <div className="d-flex flex-column">
                    <div className="fs-5 text-dark fw-bolder">
                      {outcome.title}
                    </div>
                    <div className="fs-7 fw-semibold text-gray-400">
                      {outcome.description}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <div className="form-check form-switch form-check-custom form-check-success form-check-solid">
                    <input
                      className="form-check-input w-45px h-30px"
                      type="checkbox"
                      id={"outcome" + i}
                      checked={
                        outcome_list.indexOf(outcome.value) != -1 ? true : false
                      }
                      onChange={() => select_opt(outcome)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"outcome" + i}
                    ></label>
                  </div>
                </div>
              </div>

              <div className="separator separator-dashed my-5"></div>
            </div>
          ))}
        </div>
        <div className="text-danger mt-2">
          <ErrorMessage name="outcome" />
        </div>
      </div>
    </div>
  );
};

export { Step3 };
