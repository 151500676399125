import React, { FC } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { Field, ErrorMessage } from "formik";

const Step5: FC = () => {
  return (
    <div className="w-100">
      {/* <div className="pb-10 pb-lg-15">
        <h2 className="fw-bolder text-dark">Infrastructure</h2>

        <div className="text-gray-400 fw-bold fs-6">
          If you need more info, please check out
          <a href="/dashboard" className="link-primary fw-bolder">
            {" "}
            Help Page
          </a>
          .
        </div>
      </div> */}

      <div className="fv-row mb-10">
        <label className="d-flex align-items-center form-label">
          <span className="required">Total Number of Code Repositories</span>
        </label>

        <Field
          name="repoCount"
          className="form-control form-control-lg form-control-solid"
        />
        <div className="form-text">Leave this field if you are not sure.</div>
        <div className="text-danger mt-2">
          <ErrorMessage name="repoCount" />
        </div>
      </div>

      <div className="fv-row mb-10">
        <label className="d-flex align-items-center form-label">
          <span className="required">Total Running Instances</span>
        </label>

        <Field
          name="vpsCount"
          className="form-control form-control-lg form-control-solid"
        />
        <div className="form-text">Leave this field if you are not sure.</div>
        <div className="text-danger mt-2">
          <ErrorMessage name="vpsCount" />
        </div>
      </div>

      <div className="mb-10 fv-row">
        <label className="d-flex align-items-center form-label mb-3">
          Do you have a Web Application Firewall (WAF)?
          <i
            className="fas fa-exclamation-circle ms-2 fs-7"
            data-bs-toggle="tooltip"
            title="Provide your team size to help us setup your billing"
          ></i>
        </label>

        <div className="row mb-2" data-kt-buttons="true">
          <div className="col">
            <Field
              type="radio"
              className="btn-check"
              name="waf"
              value="true"
              id="waf_true"
            />
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-3"
              htmlFor="waf_true"
            >
              <span className="fw-semibold fs-4">Yes</span>
            </label>
          </div>

          <div className="col">
            <Field
              type="radio"
              className="btn-check"
              name="waf"
              value="false"
              id="waf_false"
            />
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-3"
              htmlFor="waf_false"
            >
              <span className="fw-semibold fs-4">No</span>
            </label>
          </div>
        </div>
        <div className="text-danger mt-2">
          <ErrorMessage name="waf" />
        </div>
      </div>

      <div className="fv-row mb-10">
        <label className="d-flex align-items-center form-label">
          <span className="required">Current Monthly Budget</span>
        </label>

        <div className="input-group mb-5">
          <span className="input-group-text">£</span>
          <Field
            type="number"
            name="currentBudget"
            className="form-control"
            aria-label="Amount (to the nearest GBP)"
          />
          <span className="input-group-text">.00</span>
        </div>
        <div className="text-danger mt-2">
          <ErrorMessage name="currentBudget" />
        </div>
      </div>
    </div>
  );
};

export { Step5 };
