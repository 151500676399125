import { FC } from "react";
import { Link } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";

const Price: FC<{ estimate: { min: number; max: number } }> = ({
  estimate,
}) => {
  const currency = (n: number) => "£" + n.toLocaleString();
  return (
    <>
      <div className="card card-flush h-xl-100">
        <div
          className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px"
          style={{ backgroundImage: "url('/media/svg/shapes/top-green.png" }}
          data-theme="light"
        >
          <h3 className="card-title align-items-start flex-column text-white pt-15">
            <span className="fw-bold fs-2x mb-3">Project Estimation</span>
            {/* <div className="fs-4 text-white">
              <span className="opacity-75">Here is the project estimation to outcome1, outcome2, outcome3</span>
            </div> */}
          </h3>
        </div>
        <div className="card-body mt-n20">
          <div className="mt-n20 position-relative">
            <div className="row g-3 g-lg-6">
              <div className="col-12">
                <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                  <div className="symbol symbol-30px me-5 mb-8">
                    <span className="symbol-label">
                      <KTSVG
                        path="media/icons/duotune/finance/fin007.svg"
                        className="svg-icon svg-icon-2x  svg-icon-primary"
                      />
                    </span>
                  </div>
                  <div className="m-0 text-center">
                    <span className="text-gray-700 fw-light d-block fs-3qx lh-1 ls-n1 mb-1">
                      {currency(estimate.min)} - {currency(estimate.max)}
                    </span>
                    <span className="text-gray-500 fw-semibold fs-6">
                      Min - Max
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Price };
