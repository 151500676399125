import { FC } from "react";
import { Link } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { outcomeOptions } from "../../../services/data";

const PriceBreakdown: FC<{
  outcomes: {
    id: string;
    percentage: number;
  }[];
}> = ({ outcomes }) => {
  const breakdown = outcomes
    .map((outcome) => {
      const o = outcomeOptions.find((o) => o.value === outcome.id);
      if (o) return { ...outcome, ...o };
      return undefined;
    })
    .filter((x) => !!x) as {
    title: string;
    value: string;
    img: string;
    percentage: number;
  }[];

  return (
    <>
      <div className="card card-flush h-xl-100">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark">
              Price Breakdown
            </span>
            <span className="text-muted mt-1 fw-semibold fs-7">
              List of outcomes and their expences
            </span>
          </h3>
        </div>
        <div className="card-body pt-5 card-scroll mh-400px">
          {breakdown.map((item,i) => (
            <div key={'item_'+i}>
              <div className="d-flex flex-stack">
                <div className="d-flex align-items-center me-3">
                  <KTSVG
                    path={item.img}
                    className="svg-icon svg-icon-2x svg-icon-info me-6"
                  />
                  <div className="flex-grow-1">
                    <span className="text-gray-800 text-hover-primary fs-5 fw-bold lh-0">
                      {item.title}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center w-100 mw-125px">
                  <div className="progress h-6px w-100 me-2 bg-light-success">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: item.percentage + "%" }}
                      aria-valuenow={item.percentage}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                  {/* <span className="text-gray-400 fw-semibold">{item.percent}%</span> */}
                </div>
              </div>
              <div className="separator separator-dashed my-3"></div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export { PriceBreakdown };
